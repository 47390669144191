// https://material-ui.com/
import { AppBar, Button, IconButton, ListItemIcon, makeStyles, MenuItem, MenuList, Toolbar } from "@material-ui/core/";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import BuildIcon from "@material-ui/icons/Build";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { Link, useHistory } from "react-router-dom";
import "../../Fonts/unborneditrion.ttf";
import { withFirebase } from "../Firebase/context";
import * as ROUTES from "../routingConstants";
import BreadcrumbView from "./BreadcrumbView.jsx";
import ClusterStatus from "./ClusterStatus.jsx";
import "./Header.scss";
import HeaderAccountItem from "./HeaderAccountItem";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#78909c",
    boxShadow: "none",
    height: "42px",
  },
  toolBar: {
    minHeight: "0px",
    paddingRight: "0px",
  },
  title: {
    flexGrow: 1,
  },
  welcome: {
    paddingRight: "10px",
    marginRight: "16px",
  },
  greetings: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "700",
  },
  button: {
    color: "white",
  },
  loginBttn: {
    color: "white",
    paddingRight: "24px",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  clusterStatus: {
    paddingLeft: "8px",
  },
  linkBlack: {
    color: "black",
    textDecoration: "none",
  },
  headerBttns: {
    marginRight: "10px",
    height: "28px",
    color: "white",
    textDecoration: "none"
    // border: "1px solid gray"
  },
}));

const Header = ({ firebase, setDialogOpen }) => {
  const classes = useStyles();
  const isLoggedIn = firebase.auth.currentUser !== null;
  const history = useHistory();

  const signOut = (firebase) => {
    firebase.doSignOut();
    history.push(ROUTES.LANDINGPAGE);
  };

  function Greeting() {
    if (isLoggedIn && firebase.displayName) {
      return (
        <b className={classes.greetings}>Hello, {firebase.displayName}!</b>
      );
    }
  }

  return (
    <div className="stickyHeader">
      <AppBar className={classes.appBar} position="sticky">
        <Toolbar className={classes.toolBar}>
   
            <Link to={ROUTES.LANDINGPAGE} className={classes.link}>
              <IconButton className={classes.button}>
                <HomeIcon></HomeIcon>
              </IconButton>
            </Link>


            <Link to={ROUTES.INTRODUCTION} className={classes.link}>
              <Button
                className={classes.headerBttns}
                variant="outlined"
                size="small"
              >
                Documentation
              </Button>
            </Link>
            <Link to={ROUTES.PROOFOFWORK} className={classes.link}>
              <Button
                className={classes.headerBttns}
                variant="outlined"
                size="small"
              >
                Proof of work
              </Button>
            </Link>
          {!isLoggedIn && (
              <Link to={ROUTES.CREATEACCOUNT} className={classes.link}>
                <Button
                  className={classes.headerBttns}
                  variant="outlined"
                  size="small"
                >
                  Create account
                </Button>
              </Link>
          )}
          {isLoggedIn && (
              <Link to={ROUTES.APIKEY} className={classes.link}>
                <Button
                  className={classes.headerBttns}
                  variant="outlined"
                  size="small"
                >
                  API-Key history
                </Button>
              </Link>
          )}
          <Divider orientation="vertical" flexItem />

          <ClusterStatus></ClusterStatus>

          <span className={classes.title}></span>
          <span className={classes.welcome}> {Greeting()}</span>
          {isLoggedIn ? (
            <HeaderAccountItem>
              <MenuList id="menu-list-grow">
                <Link className={classes.linkBlack} to={ROUTES.ACCOUNTOVERVIEW}>
                  <MenuItem>
                    <ListItemIcon>
                      <BuildIcon fontSize="small" />
                    </ListItemIcon>
                    Manage account
                  </MenuItem>
                </Link>
                <MenuItem onClick={() => signOut(firebase)}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuList>
            </HeaderAccountItem>
          ) : (
            <Button
              className={classes.loginBttn}
              onClick={() => setDialogOpen(true)}
            >
              <HowToRegIcon></HowToRegIcon> Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <BreadcrumbView></BreadcrumbView>
    </div>
  );
};

export default withFirebase(Header);
