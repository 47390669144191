// https://material-ui.com/
import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import LinkIcon from '@material-ui/icons/Link';
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { Link } from "react-router-dom";
import myVideo from "../../../../VideoTutorials/cspldownload.mp4";
import * as ROUTES from "../../../routingConstants";
import "../fonts.scss";
import "./Installation.scss";
// https://reacttraining.com/react-router/web/guides/quick-start
import { useHistory } from "react-router-dom";
import {withFirebase} from '../../../Firebase/context';
// https://github.com/iamhosseindhv/notistack
import {withSnackbar} from 'notistack';


const  Installation = (props)  => {
  const [dense] = React.useState(true);
  const history = useHistory();
  window.scrollTo(0, 0);


  const openLoginPlease = () => {
    props.enqueueSnackbar('Please login to see this page.', {variant: 'error', autoHideDuration: 1500});
  };

  const handleApikeyClick = ()  => {
    if(props.firebase.isLoggedIn){
      history.push(ROUTES.MANAGEACCOUNT);
    } else {
      openLoginPlease();
    }
  }
  return (
    <div>
      <div className="documentationContent">
        <h1>Before you start</h1>
        <h4>Prerequisites</h4>
        <Typography>
          There are two prerequisites before you can go on and use CSPL.{" "}
        </Typography>
        <List dense={dense}>
          <ListItem>
            <ListItemIcon>
              <ImageSearchIcon className="iconSize" />
            </ListItemIcon>
            <ListItem
              button
              component="a"
              href="https://www.nuget.org/packages?q=cspl"
            >
              <ListItemText
                primary={
                  <Typography type="body2" style={{ color: "#083d8d" }}>
                    Download the NuGet package
                  </Typography>
                }
                secondary="Links to CSPL on NuGet.org"
              />
            </ListItem>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkIcon className="iconSize" />
            </ListItemIcon>
            <ListItem
              button
              component="a"
              onClick={() => handleApikeyClick()}
            >
              <ListItemText
                primary={
                  <Typography type="body2" style={{ color: "#083d8d" }}>
                    Get your API-key
                  </Typography>
                }
                secondary="Links to account management"
              />
            </ListItem>
          </ListItem>
        </List>

        <h4 id="download">Download Nuget package</h4>
        <Typography>
          If you are using visual studio to manage NuGet packages, please go{" "}
          <span className="italic">"Manage NuGet packages for solution"</span>{" "}
          under
          <span className="italic"> tools -> NuGet package manager.</span>
        </Typography>
        <Typography>
          Now access the settings icon in the top right corner.
        </Typography>
        <div className="videoDiv">
          <video className="videoStyling" src={myVideo} height="500px" muted autoPlay loop></video>
        </div>
        <p></p>
        <div style={{ marginBottom: "30px" }}>
          <Typography>
            Now you should be able to use the package{" "}
            <span>ClusterSupportedParallelLibrary.</span>
          </Typography>
          <Typography style={{ display: "flex", alignItems: "center" }}>
            <ArrowRightIcon></ArrowRightIcon> <span>Continue to <Link to={ROUTES.IMPLEMENTATION}>implementation</Link></span>

          </Typography>
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(withFirebase(Installation))
