// https://material-ui.com/
import { Button } from "@material-ui/core/";
import 'firebase/functions';
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { useHistory } from "react-router-dom";
import "../../Fonts/unborneditrion.ttf";
import * as ROUTES from "../routingConstants";
import "./LandingPage.scss";

// Required for side-effects
require("firebase/functions");

const LandingPage = () => {
  const history = useHistory();
  const goToImplementation = () => {
    history.push(ROUTES.INTRODUCTION);
  };

  return (
    <div>
      <header className="v-header container">
        <div className="header-content text-md-center">
          <h1 className="TEST">Cluster supported parallel libraries</h1>
          <p>
            A new way of parallising programs! Cluster supported parallel
            libraries (shortend CSPL.) is the future for handeling and
            processing programs. Inspired by NVIDIAS game stream, cspl. offers a
            responsive cluster for fast remote dll processing of your programs.
            What started as an idea, is now a working project which promises
            performance gains for any large scale project.
          </p>
          <Button
            variant="outlined"
            style={{ color: "#9fa5c6" }}
            onClick={goToImplementation}
          >
            Find Out More
          </Button>
        </div>
      </header>
    </div>
  );
};

export default LandingPage;
