// https://material-ui.com/
import { Typography } from "@material-ui/core";
import React, { Component } from "react";
// https://www.npmjs.com/package/react-embed-gist
import ReactEmbedGist from "react-embed-gist";

const ImplementationSection = ({ children, header }) => {
  return (
    <div>
      <p></p>
      <h4>{header}</h4>
      {children}
    </div>
  );
};

class Implementation extends Component {

  render() {
    return (
      <div className="documentationContent">
        <h1>Implementation of CSPL interface</h1>
        <InterfaceSection></InterfaceSection>
        <InterfaceParameters></InterfaceParameters>
        <SplitSection></SplitSection>
        <ParallelSection></ParallelSection>
        <CollectSection></CollectSection>
        <FullImplementationSection></FullImplementationSection>
      </div>
    );
  }
}

const InterfaceSection = () => {
  return (
    <div>
      <ImplementationSection header="Interface">
        <Typography>
          After downloading the NuGet package, an interface becomes available to
          implement. In this section we will take a look at the diffrent methods
          in the interface and how to implement them with examples. Here is a
          snippet of the methods in the interface. One thing to notice is the
          APIkey field. This is where you will put your API-key that you get
          from manage account.
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/60e1dd14bcf2b8f7614e98bf873d2068"></ReactEmbedGist>
        </ul>
        <Typography>
          First off one has to specify four generic types for the constructor
          "TDataSource, TParallelArgs, TSubResult, TFinalResult". Each of these
          are representing either the input or output parameter for the three
          methods in the interface.
        </Typography>
      </ImplementationSection>
    </div>
  );
};

const InterfaceParameters = () => {
  return (
    <div>
      <ImplementationSection header="Interface parameters">
        <Typography>
          The parameter list of IClusterParallelizer is designed so that the
          input of the methods are the same as the output of the previous method
          (with Split as an exception). It comes as either a list or single
          object depending on the method. It is totally up to the programmer who
          are implementing the interface to figure out which types should be
          used.
        </Typography>
        <p></p>
        <Typography>
          On the code snippet below, it is shown how specify the types, when
          implementing a class which implements the interface.{" "}
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/d36f9d24abea48bcbe5e2d25fe4ea9a8"></ReactEmbedGist>
        </ul>
      </ImplementationSection>
    </div>
  );
};

const SplitSection = () => {
  return (
    <div>
      <ImplementationSection header="Split">
        <Typography>
          The split method takes the datasource (if any) as a parameter and
          throws out a list of generic objects. The list of objects returned are
          the input data for the parallel method. The implementation of split
          should somehow slice the dataset into smaller chunks.
        </Typography>
        <p></p>
        <Typography>
          The implementation of the split method will define two things
        </Typography>
        <ul>
          <li>
            <Typography>
              <b>How many nodes on the cluster you want</b> is specified by the
              number of elements in the list that is returned by the method. So
              a split method like this will return a list of 4 elements and will
              therefore make 4 request to the cluster. This could also be 8 or
              16, 15 or 3. That depends on how it makes most sense to split up
              the datasource.{" "}
            </Typography>
          </li>
          <ReactEmbedGist gist="201508876PMH/e4ce210b618744b8fab67ea98056cd4e"></ReactEmbedGist>
          <li>
            <Typography>
              <b>How your data should be split up</b> is just a specification of
              how it actually should be split up. It is recommended to split the
              data up in equally big chunks, as it will give the nodes on the
              cluster a better chance of being done at the same time. This will
              give a better performance.
            </Typography>
          </li>
        </ul>
      </ImplementationSection>
    </div>
  );
};

const ParallelSection = () => {
  return (
    <div>
      <ImplementationSection header="Parallel">
        <Typography>
          The parallel method is the method which runs on each requested node on
          the cluster. The method takes one of the produced chunks returned by
          the split method as the parameter. The chunk can be of any type either
          a string, a Book object or a list of Car objects. The input parameter
          is of the same type as the list type returned from the split method
          (if any).{" "}
        </Typography>
        <p></p>
        <Typography>
          In this example the parallel method is rather simple. It takes a list
          of floats as input argument and returns the sum.
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/5592aa8aa1b66b3749cc0b3bd7708cda"></ReactEmbedGist>
        </ul>
      </ImplementationSection>
    </div>
  );
};

const CollectSection = () => {
  return (
    <div>
      <ImplementationSection header="Collect">
        <Typography>
          The collect is where you implement the functionality to gather the
          results from each of the executed parallel functions. It takes a list
          of whatever the parallel function return type is so it can accumulate
          the result.
        </Typography>
        <p></p>
        <Typography>
          In this example the collect type is simple summing up the list of
          summed resulst from the parallel function.
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/03f13bd8cead4f967ffb1975800c87ff"></ReactEmbedGist>
        </ul>
        <Typography></Typography>
      </ImplementationSection>
    </div>
  );
};

const FullImplementationSection = () => {
  return (
    <div>
      <ImplementationSection header="Full implementation">
        <Typography>
          A overview of the whole implementation of the class.{" "}
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/1b72c0ed3bae1e35f6bd2d4cb4f5a3ce"></ReactEmbedGist>
        </ul>
        <p></p>
        <Typography>
          The following is a snippet of the derived class calling run. The
          datasource given to the run function should be any list of floats in
          this situation
        </Typography>
        <ul>
          <ReactEmbedGist gist="201508876PMH/baf22bcca758194ee96359ac26f6fb81"></ReactEmbedGist>
        </ul>
        <Typography></Typography>
      </ImplementationSection>
    </div>
  );
};

export default Implementation;
