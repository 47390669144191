import React from "react";
// https://reactjs.org/docs/typechecking-with-proptypes.html
import PropTypes from 'prop-types';
// https://material-ui.com/
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  FormControl,
  FormLabel,
  InputLabel,
  InputAdornment,
  Button,
  Input
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RadioWithCustom from "../forms/RadioWithCustom";
import TextFieldWithPattern from "../forms/TextFieldWithPattern";
import {withFirebase} from "../Firebase/context";

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingBottom: "12px"
  },
  SelectNumber: {
    paddingTop: "30px"
  },
  textFieldRenewAPIKey: {
    display: "Flex"
  }
}));

const AccountDialog = ({
  open,
  handleClose,
  userAPIKey,
  firebase,
  setOpenSnackBarPurchase,
  setOpenSnackBarRenew
}) => {
  const classes = useStyles();
  const [paymentAmount, setPaymentAmoumt] = React.useState(1);

  const handleChangePaymentAmount = value => {
    setPaymentAmoumt(value / 100);
  };

function Purchase(){
  if(userAPIKey === "No API Key purchased..."){
    firebase.replaceUserAPIKey(paymentAmount*100);
    setOpenSnackBarPurchase()
    handleClose();
  }else{
    firebase.renewUserAPIKey(paymentAmount*100);
    setOpenSnackBarRenew()
    handleClose();
  }
}
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Renew your API key.</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText>
        <div className={classes.rowContainer}>
          <TextField
            autoFocus
            fullWidth
            label="Name on card"
            className={classes.textFieldRenewAPIKey}
            margin="dense"
            variant="outlined"
          />
        </div>
        <div className={classes.rowContainer}>
          <TextFieldWithPattern
            pattern={/^([0-9]*)$/}
            fullWidth
            label="Credit card number"
            className={classes.textFieldRenewAPIKey}
            margin="dense"
            variant="outlined"
          />
        </div>
        <div className={classes.rowContainer}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextFieldWithPattern
                pattern={/^([0-9]*)$/}
                className={classes.textFieldRenewAPIKey}
                label="Expiry date"
                helperText="MM"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldWithPattern
              pattern={/^([0-9]*)$/}
                className={classes.textFieldRenewAPIKey}
                helperText="YYYY"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldWithPattern
              pattern={/^([0-9]*)$/}
                className={classes.textFieldRenewAPIKey}
                label="CV code" 
                helperText="ex. 311"
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.SelectNumber}>
              Select number of key requests: ($0.1 pr. call )
            </FormLabel>
            <RadioWithCustom
              name="amount"
              fields={[
                {
                  value: "100"
                },
                {
                  value: "1000"
                },
                {
                  value: "10000"
                }
              ]}
              onChange={handleChangePaymentAmount}
            />
          </FormControl>
          <FormControl fullWidth className={classes.SelectNumber}>
            <InputLabel
              className={classes.SelectNumber}
              htmlFor="standard-adornment-amount"
            >
              Final payment:
            </InputLabel>
            <Input
              disabled
              id="standard-adornment-amount"
              value={paymentAmount}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
            />
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={Purchase} color="primary">
          Purchase
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}
AccountDialog.defaultProps = {
  handleClose: () => {},
}


export default withFirebase(AccountDialog);
