// https://material-ui.com/
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import HorizontalbarChart from "./HorizontalbarChart.jsx";
import LineChart from "./LineChart.jsx";
import TimelineChart from "./TimelineChart.jsx";
import HorizontalChartCluster from "./HorizontalChartCluster.jsx";
import HorizontalChartClusterLinear from "./HorizontalChartClusterLinear.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  Grid: {
    margin: "auto",
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className={classes.root}>
        <Typography variant="subtitle1" gutterBottom>
          <b>Single Node, Multi-threaded performance</b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            The graf depicted to the right shows the difference in performance
            executing a pi calcultion task. The Desktop has Ryzen 7 2700x
            3.7Ghz, 8 cores w. hyperthreading CPU, the laptop with a i7-8550U
            1.99Ghz 4 cores w. hyperthreading CPU and the Raspberry Pi with a
            Broadcom BCM2837 1.2Ghz 4 cores CPU. These 3 platforms are compared
            with eachother, where the pi calculation program has beeen optimised
            for parallelism from 1 to 16 threads.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <HorizontalbarChart></HorizontalbarChart>
          </Grid>
        </Grid>
        <Divider style={{ margin: "50px" }} />
        <Typography variant="subtitle1" gutterBottom>
          <b>Multi node, single-threaded performance</b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            This graf shows the effects of having mutiple Raspberry Pi's bundled
            together. The cluster is using the CSPL library and effectivly
            parallising the pi calculation program with somewhat Linear gain
            factor up to 80 pods. Here we can see the cluster of just 20
            Raspberry Pis outperforming the laptop when using up to more than 32
            pods.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <TimelineChart></TimelineChart>
          </Grid>
        </Grid>
        <Divider style={{ margin: "50px" }} />
        {/* <Typography variant="subtitle1" gutterBottom>
          <b>Logarithmic time scaling </b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            The purpose of the following twp grafs is to show which gains can be
            made by calculating pi with over 8Billion to 100 Billion iterations.
            Looking at graf one, we see that increasing the number of pods has a
            logarithmically increase to time. Whereas looking at graf two, we
            can see that the calculated gain factor is close to linear when
            having enough calucaled iterations.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <HorizontalChartCluster></HorizontalChartCluster>
          </Grid>
        </Grid> */}
        <Typography variant="subtitle1" gutterBottom style={{ marginTop: "50px" }}>
          <b>Linear gain scaling </b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            Here it is shown how the actual gains factor nears the theoretical
            gains factor. When looking at the tests done with 8 billion vs 100
            billion, we can see that increasing the number of iterations, results in
            the actual measurements geting even closer to theoretical gain.

          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <HorizontalChartClusterLinear></HorizontalChartClusterLinear>
          </Grid>
        </Grid>

        <Divider style={{ margin: "50px" }} />
        <Typography variant="subtitle1" gutterBottom>
          <b>HW. Difference, Raspberry Pi 3B vs. 3B+ </b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            This test has the purpose of showing which performanc gains can be
            acheved by small HW. upgrades. The differences between a raspberry
            pi 3B and a Raspberry Pi 3B+ are small, but sufficient enough to see
            changes. Where the two differ is primarly in the CPU department,
            where the B+ has a 1.4Ghz processor and the Pi B of 1.2Ghz.
            <br></br>
            Tests conducted with 1, 2 and 4 threads are executed on a single pi.
            Whereas the 20-pod test is a cluster of 5 Pi's, with 4 pods each,
            which correlates to 20 threads.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-30px" }}>
            <LineChart></LineChart>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
