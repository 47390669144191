import React from "react";
// https://material-ui.com/
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withFirebase } from "../Firebase/context";
// https://reactjs.org/docs/typechecking-with-proptypes.html
import PropTypes from "prop-types";

const ReplaceAPIKeyDialog = ({ open, handleClose, firebase, setOpenSnackBar, callsLeft }) => {

  function Purchase() {
    firebase.replaceUserAPIKey(callsLeft);
    setOpenSnackBar(true)
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to permently delete your API-Key?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By pressing agree, your current API-key will be permently deleted
            and a new one will be generated instead.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={Purchase} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ReplaceAPIKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
};
ReplaceAPIKeyDialog.defaultProps = {
  handleClose: () => {}
};
export default withFirebase(ReplaceAPIKeyDialog);
