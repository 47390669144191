// https://material-ui.com/
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GitHubIcon from "@material-ui/icons/GitHub";
import React from "react";
import ContentTabs from "./cotentTabs.jsx";
import "./ProofOfWork.scss";
import ProofOfWorkTable from "./ProofOfWorkTable.jsx";
import ProofOfWorkTableTwo from "./ProofOfWorkTableTwo.jsx";
import ProofOfWorkTableThree from "./ProofOfWorkTableThree.jsx";
import ProofOfWorkTableFour from "./ProofOfWorkTableFour.jsx";
import Divider from '@material-ui/core/Divider';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  title: {
    margin: theme.spacing(3, 0, 0),
    fontSize: "14px",
  },
  bttnGroup: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ProofOfWork = () => {
  const classes = useStyles();
  const [dense] = React.useState(true);

  return (
    <div className="mainFrameProofOfWork">
      <div className="title">Proof of work</div>
      <div style={{ fontSize: "14px" }}>
        <div style={{ width: "100%" }}>
          The purpose of this page is to illustrate how cluster supported
          parallel libraries promises performance gains in various applications.
          The tests created to show this, are respectively two edge cases. One
          where the package, which is sent to the cluster is large, but the algorithm
          behind it simple. And one where the package is small, but with a
          complex algorithm. The tables below is the hardware of the cluster as
          of date 27/04/2020 and has the purpose to show the performance gains,
          able to be aquired from this setup.
        </div>
        <Grid style={{ width: "100%" }} container spacing={1}>
          <Grid style={{ maxWidth: "100%" }} item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Links to information about the test projects:
            </Typography>
            <div className={classes.demo}>
              <List dense={dense}>
                <ListItem>
                  <ListItemIcon>
                    <BlurCircularIcon className="iconSize" />
                  </ListItemIcon>
                  <ListItem
                    button
                    component="a"
                    href="https://www.101computing.net/estimating-pi-using-the-monte-carlo-method/"
                  >
                    <ListItemText
                     primary={
                      <Typography type="body2" style={{color: "#083d8d"}}>
                        https://www.101computing.net/estimating-pi-using-the-monte-carlo-method/
                      </Typography>
                    }
                      secondary="Montecarlo pi estimation"
                    />
                   
                  </ListItem>
                </ListItem>
              </List>
              <List dense={dense}>
                <ListItem>
                  <ListItemIcon>
                    <LockOpenIcon className="iconSize" />
                  </ListItemIcon>
                  <ListItem
                    button
                    component="a"
                    href="https://rosettacode.org/wiki/Parallel_Brute_Force"
                  >
                    <ListItemText
                     primary={
                      <Typography type="body2" style={{color: "#083d8d"}}>
                        https://rosettacode.org/wiki/Parallel_Brute_Force
                      </Typography>
                    }
                      secondary="Bruce force SHA256 hashing"
                    />
                   
                  </ListItem>
                </ListItem>
              </List>
            </div>
          </Grid>

          <Grid style={{ maxWidth: "100%" }} item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Primary cluster HW:
            </Typography>
            <div className={classes.demo}>
              <List dense={dense}>
                <ListItem>
                  <ProofOfWorkTable></ProofOfWorkTable>
                </ListItem>
              </List>
            </div>
          </Grid>
          <Grid style={{ maxWidth: "100%" }} item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
              Supportive cluster HW:
            </Typography>
            <div className={classes.demo}>
              <List dense={dense}>
                <ListItem>
                  <ProofOfWorkTableTwo></ProofOfWorkTableTwo>
                </ListItem>
              </List>
            </div>
          </Grid>
        
          <Grid style={{ maxWidth: "100%" }} item xs={12} md={6}>
          <Divider style={{margin: "30px"}}/>
            <Typography variant="h6" className={classes.title}>
              External HW. for benchmarking:
            </Typography>
            <div className={classes.demo}>
              <List dense={dense}>
                <ListItem>
                  <ProofOfWorkTableThree></ProofOfWorkTableThree>
                </ListItem> 
              </List>
            </div>
          </Grid>
          <Grid style={{ maxWidth: "100%" }} item xs={12} md={6}>
            <Typography variant="h6" className={classes.title}>
            <InfoOutlinedIcon style={{verticalAlign: "bottom"}}></InfoOutlinedIcon> General information:
            </Typography>
            <div className={classes.demo}>
              <List dense={dense}>
                <ListItem>
                  <ProofOfWorkTableFour></ProofOfWorkTableFour>
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
        <div>
          <div className={classes.bttnGroup}>
          <ContentTabs></ContentTabs>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProofOfWork;