// https://material-ui.com/
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Component } from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { Link as RouterLink, Route, Switch, useHistory } from 'react-router-dom';
import * as ROUTES from '../routingConstants';
import './Documentation.scss';
import Examples from "./sections/Example/Examples";
import GettingStarted from './sections/GettingStarted/GettingStarted';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import Implementation from './sections/Implementation/Implementation';
import Installation from './sections/Installation/Installation';
import Introduction from './sections/Introduction/Introduction';

var classNames = require('classnames');
const breadcrumbNameMap = {
    [ROUTES.GETTINGSTARTED] : 'Getting started',
    [ROUTES.INSTALLATION]: 'Installation',
    [ROUTES.HOWITWORKS]: 'How it works',
    [ROUTES.EXAMPLES]: 'Examples',
    [ROUTES.INTRODUCTION]: 'Introduction',
    [ROUTES.IMPLEMENTATION]: 'Implementation',

};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 360,
    },
    lists: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(1),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

function ListItemLink(props) {
    const { to, open, ...other } = props;
    const primary = breadcrumbNameMap[to];
    return (
        <li>
            <ListItem button component={RouterLink} to={to} {...other}>
                <ListItemText primary={primary} />
                {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
        </li>
    );
}

export default class Documentation extends Component {
    render() {
        return (
            <div>
                <div className='wrapper'>
                    <Drawer></Drawer>
                    <div className="mainFrame">
                    <Switch>
                            <Route exact path={ROUTES.GETTINGSTARTED} component={GettingStarted} />
                            <Route exact path={ROUTES.INTRODUCTION} component={() => <Introduction firebase={this.props.firebase}></Introduction>} />
                            <Route path={ROUTES.HOWITWORKS} component={() => <HowItWorks ></HowItWorks>} />
                            <Route path={ROUTES.EXAMPLES} component={() => <Examples ></Examples>} />
                            <Route path={ROUTES.INSTALLATION} component={() => <Installation ></Installation>} />
                            <Route path={ROUTES.IMPLEMENTATION} component={() => <Implementation ></Implementation>} />
                    </Switch>
                    </div>
                </div>
            </div>
        )
    }
}

export function Drawer() {
    const [open, setOpen] = React.useState(true);
    const [route, setRoute] = React.useState(ROUTES.INTRODUCTION)
    const [active, setActive] = React.useState(false)

    const history = useHistory();

    const handleClickCss = (routeChange) => {
        setRoute(routeChange);
    }
    if (route !== history.location.pathname) {

        handleClickCss(history.location.pathname);
    }
    const classes = useStyles();
    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
        setRoute(ROUTES.GETTINGSTARTED);
    };
    const setArrowActive = () => {
        setActive(true)
    }
    const setArrowNotActive = () => {
        setActive(false);
    }
    return (
        <div >
            <aside className="mdc-drawer">
                <div className="">
                    <nav className="nav-list">
                        <ListItemLink to={ROUTES.INTRODUCTION} onClick={() => handleClickCss(ROUTES.INTRODUCTION)} className={route === ROUTES.INTRODUCTION ? 'blueColor' : ''} />
                        <div className="cursorLink" onMouseEnter={() => setArrowActive()} onMouseLeave={() => setArrowNotActive()}>
                            <ListItem open={open} onClick={handleClick} className={route === ROUTES.GETTINGSTARTED ? 'blueColor' : ''}>
                                <ListItemText >Getting started</ListItemText>
                                <TableSortLabel direction={open === true ? 'desc' : 'asc'} active={active}></TableSortLabel>
                            </ListItem>
                        </div>
                        <Collapse className={classes.cursorLink} component="li" in={open} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <ListItemLink to={ROUTES.INSTALLATION} onClick={() => handleClickCss(ROUTES.INSTALLATION)}
                                    className={classNames([classes.nested, route === ROUTES.INSTALLATION ? 'blueColor' : ''])} />
                                <ListItemLink to={ROUTES.IMPLEMENTATION} onClick={() => handleClickCss(ROUTES.IMPLEMENTATION)}
                                    className={classNames([classes.nested, route === ROUTES.IMPLEMENTATION ? 'blueColor' : ''])} />
                            </List>
                        </Collapse>
                        <ListItemLink to={ROUTES.HOWITWORKS} onClick={() => handleClickCss(ROUTES.HOWITWORKS)} className={route === ROUTES.HOWITWORKS ? 'blueColor' : ''} />
                        <ListItemLink to={ROUTES.EXAMPLES} onClick={() => handleClickCss(ROUTES.EXAMPLES)} className={route === ROUTES.EXAMPLES ? 'blueColor' : ''} />
                    </nav>
                </div>
            </aside>
        </div>
    )

}



