import React from "react";
// https://reactjs.org/docs/typechecking-with-proptypes.html
import PropTypes from "prop-types";
// https://material-ui.com/
import { RadioGroup, Radio, TextField, FormControlLabel } from "@material-ui/core";

const RadioWithCustom = ({
  fields,
  name,
  row,
  onChange
}) => {
  const [amount, setAmount] = React.useState(fields[0].value);
  const [checkedIndex, setCheckedIndex] = React.useState(0);

  const handleInputChange = (e, i) => {
    const { value } = e.target;

    setCheckedIndex(i);
    setAmount(value);
  }

  React.useEffect(() => {
    // Call onChange prop callback
    onChange(amount);
  }, [onChange, amount]);

  return (
    <RadioGroup name={name} row={row}>
      {fields.map((item, i) => (
        <FormControlLabel
          key={item.value}
          value={item.value}
          label={item.title || item.value}
          onChange={(e) => handleInputChange(e, i)}
          checked={checkedIndex === i}
          control={<Radio color="primary" />}
        />
      ))}
      <TextField
        onChange={(e) => handleInputChange(e, -1)}
        onFocus={() => setCheckedIndex(-1)}
        label="Custom Amount"
        margin="dense"
        variant="outlined"
      />
    </RadioGroup>
  );
};

RadioWithCustom.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  row: PropTypes.bool,
  onChange: PropTypes.func
};
RadioWithCustom.defaultProps = {
  onChange: () => console.log("test"),
  row: false
};

export default RadioWithCustom;
