// https://github.com/iamhosseindhv/notistack
import { SnackbarProvider } from 'notistack';
import React, { Component } from "react";
import "./App.scss";
import Animation from "./components/coolAFBackground/background";
import { withFirebase } from "./components/Firebase";
import NewHome from "./components/homeComponent/Home.jsx";
import logo from "./logo.svg";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: null,
    };
  }

  componentDidMount() {
    this.props.firebase.auth.onAuthStateChanged((authUser) => {
      authUser
        ? this.setState({ authUser })
        : this.setState({ authUser: null });
    });
    new Animation("hero-background");
  }

  render() {
    return (
      <SnackbarProvider maxSnack={5}>
      <div className="App">
        <div className="background">
          <canvas id="hero-background"></canvas>
        </div>
        <NewHome className="home" firebase={this.props.firebase}></NewHome>
        <div className="FooterDiv">
          <Footer></Footer>
        </div>
      </div>
      </SnackbarProvider>

    );
  }
}

const Footer = () => {
  const current_datetime = new Date();
  return (
    <div className="footerContainer">
      <hr className="divider"></hr>

      <div className="copyRight">@Copyright CSPL gutterne</div>
      <div className="footer">
        <div className="flex">
          <img src={logo} className="App-logo-footer App-logo" alt="logo" />
          <div className="marginLeft">
            Last updated{" "}
            {current_datetime.getDate() +
              "-" +
              (current_datetime.getMonth() + 1) +
              "-" +
              current_datetime.getFullYear()}
          </div>
          <div className="marginLeft">Cluster Supported Parallel Libraries</div>
          <div className="marginLeft">
            Andreas Schjødt, Oliver Monberg, Peter Marcus, Anton Sihm
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(App);
