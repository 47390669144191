import React from "react";





export default function GettingStarted() {
    return (
        <div className='documentationContent'>
            <h1>Getting started with Cluster supported parallel library</h1>
        </div>
    )
}

