// https://material-ui.com/
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import DockerChart from "./DockerChart.jsx";
import LineChart from "./LineChart.jsx";
import TimelineChart from "./TimelineChart.jsx";
import SSLBarChart from "./SSLBarChart.jsx";
import KubernetesOverhead from "./KubernetesOverhead.jsx";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  Grid: {
    margin: "auto",
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className={classes.root}>
        <Typography variant="subtitle1" gutterBottom>
          <b>Process with and without Docker</b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            To ensure that wrapping the process in a docker container didnt have
            any significant performance bottlenecks a test was made to compare a
            process with and without Docker. Here it is shown that there is
            infact a small performance loss in using Docker, but insignificant
            in the wider picture.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <DockerChart></DockerChart>
          </Grid>
        </Grid>
        <Divider style={{ margin: "50px" }} />
        <Typography variant="subtitle1" gutterBottom>
          <b>Data sending overhead</b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            This graf shows how a large data set can result in an overhead, when sending to CSPL.
            When looking at the large data set, specifically the green bar, one might think he was saving time
            by using CSPL. However when not calculating the time to send the data, the badwidth delay results
            in a lot of added time, which therefor might be counterintuitive. It is therefor important to take note
            of what is sent and evaluate if the job is heavy enough for CSPL. 
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <SSLBarChart></SSLBarChart>
          </Grid>
        </Grid>
        <Divider style={{ margin: "50px" }} />
        <Typography variant="subtitle1" gutterBottom>
          <b>Overhead with Kubernetes </b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            This test shows the overhead tradeoff by using Kubernetes. This test
            shows the difference in peformance for a Raspberry Pi 3B and
            Raspberry Pi 3B+ with docker inside a kubernetes pod. These tests
            also include the required pre-processing of data before data is sent
            to each pod for work.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <KubernetesOverhead></KubernetesOverhead>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
