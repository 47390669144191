import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class DockerChart extends React.Component {
  render() {
    return (
      <div>
        <Chart
          width={"100%"}
          height={"250px"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "1 Thread", "2 Threads", "4 Threads"],

            ["w/ Docker Raspberry Pi 3B+", 1169.53, 630.65, 354.81],
            ["w/o Docker Raspberry Pi 3B+", 1145.47, 585.76, 347.85],
            ["w/ Docker Raspberry Pi 3B", 1370.70, 683.97, 396.28],
            ["w/o Docker Raspberry Pi 3B", 1334.67, 668.01, 362.04],
          ]}
          options={{
            title: "8000 million iterations, 1 Raspberry Pi, w/ and w/o Docker",
            chartArea: { width: "55%", height: "65%" },
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            hAxis: {
              title: "Execution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
