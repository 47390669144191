import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class HorizontalbarChart extends React.Component {
  render() {
    return (
      <div>

        <Chart
          width={"100%"}
          height={"100%"}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["x", "Time for specific number of pods"],
            [1, 1259.39],
            [2, 683.13],
            [4, 344.71],
            [8, 177.74],
            [16, 95.04],
            [32, 54.14],
            [64, 32.99],
            [80, 29.84],
          ]}
          options={{
            pointSize: 5,
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            title: "8000 million iterations, logarithmic scaling",
            chartArea: { width: "70%", height: "65%" },
            hAxis: {
              title: "Pods",
            },
            vAxis: {
              title: "Time",
            },
          }}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
