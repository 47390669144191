import React from "react";
// https://material-ui.com/
import { IconButton, makeStyles } from "@material-ui/core/";
import { AccountCircle } from "@material-ui/icons";
import HeaderDropdown from "./HeaderDropdown";

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
    padding: "0px"
  }
}));

const HeaderAccountItem = ({ children }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => setOpen(!open);
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        edge="start"
        ref={anchorRef}
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <AccountCircle />
      </IconButton>
      <HeaderDropdown
        open={open}
        handleClose={handleClose}
        anchorRef={anchorRef}
      >
        {children}
      </HeaderDropdown>
    </div>
  );
};

export default HeaderAccountItem;
