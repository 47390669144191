import React from 'react';
// https://material-ui.com/
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, Role, pcs, Cost, Speed) {
  return { name, Role, pcs, Cost, Speed };
}

const rows = [
  createData('Cisco SG112-24 ',  "switch", 1, "477,00 kr.", "10/100/1000 MBps"),
  createData('Cisco Linksys WRT320N ',  "router", 1, "217,00 kr.", "10/100/1000 MBps"),
  createData('Kingston SSDNow A400 SSD ',  "master SSD", 1, "242,00 kr.", "500 MBps(read) / 320 MBps (write)"),
  createData('CAT.5e ', "LAN cables",24,  "~ 22,00 kr.", "1000 MBps"),
];

export default function DenseTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{width: "1100px"}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Platform (device)</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Pcs.</TableCell>
            <TableCell align="right">Cost pr. pcs.</TableCell>
            <TableCell align="right">Speed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.Role}</TableCell>
              <TableCell align="right">{row.pcs}</TableCell>
              <TableCell align="right">{row.Cost}</TableCell>
              <TableCell align="right">{row.Speed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}