// https://material-ui.com/
import { makeStyles } from "@material-ui/core/";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React, { useEffect } from "react";
import { withFirebase } from "../Firebase/context";



const useStyles = makeStyles((theme) => ({
  bttn: {
    height: "28px",
    color: "white",
    marginLeft: "10px",
  },
  textCss: {
    fontSize: "12px"
  },
  statusSuccess: {
    color: "#6cb96c",
  },
  statusFailed: {
    color: "#d05656",
  },
  gridAlign:{
    textAlign: "end"
  }
}));

const PopoverPopupState = ({ firebase }) => {
  const classes = useStyles();
  const [clusterStatus, setClusterStatus] = React.useState(true);
  const [clusterTimestamp, setClusterTimestamp] = React.useState(true);
  const [workerPodReplicas, setWorkerPodReplicas] = React.useState(true);
  const timestamp = React.useRef();
  const [numberOfNodesInCluster, setNumberOfNodesInCluster] = React.useState(
    ""
  );

  const fetchClusterStatusAndUpdateStates = async () => {
    const result = await firebase.getClusterStatus();
    var newTimeStamp = result.data().DateTime.seconds;
    if (newTimeStamp !== timestamp.current) {
      timestamp.current = newTimeStamp;
      setClusterStatus(true);
      setClusterTimestamp(getDateFormat(new Date(newTimeStamp * 1000)));
      setWorkerPodReplicas(result.data().WorkerPodReplicas);
      setNumberOfNodesInCluster(result.data().WorkerPodReplicas);
    } else {
      setClusterStatus(false);
      setNumberOfNodesInCluster(0);
    }
  };

  const getDateFormat = (date) => {
    //   let formattedDate = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    //     return formattedDate;

    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime =
      hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

    return formattedTime;
  };

  const setStatusInterval = async () => {
    setInterval(async () => {
      try {
        fetchClusterStatusAndUpdateStates();
      } catch (error) {
        console.log(error);
      }
    }, 30000);
  };
  useEffect(() => {
    fetchClusterStatusAndUpdateStates();
    setStatusInterval();
  }, []);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          {clusterStatus === true ? (
            <Tooltip arrow title="Cluster is online">
              <Button
                className={classes.bttn}
                variant="outlined"
                color="primary"
                size="small"
                {...bindTrigger(popupState)}
                startIcon={<FiberManualRecordIcon style={{color: "#8FD38F"}} />}
               
              >
                Status of cluster
              </Button>
            </Tooltip>
          ) : (
            <Tooltip arrow title="Cluster is offline">
               <Button
                className={classes.bttn}
                variant="outlined"
                color="primary"
                size="small"
                {...bindTrigger(popupState)}
                startIcon={<FiberManualRecordIcon  style={{color: "#79213A"}}/>}
              >
                Status of cluster
              </Button>
            </Tooltip>
          )}
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box p={2} style={{ width: "320px" }}>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <span className={classes.textCss}>
                    Nr. of workers in the cluster:
                  </span>
                </Grid>
                <Grid item xs={3} className={classes.gridAlign} >
                  <span className={classes.textCss}>
                    {clusterStatus ===  true ? (numberOfNodesInCluster) : ('Offline')}
                    {/* {numberOfNodesInCluster} */}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={9}>
                  <span className={classes.textCss}>Time since last ping:</span>
                </Grid>
                <Grid item xs={3} className={classes.gridAlign} >
                  <Tooltip
                    arrow
                    placement="right"
                    title="Cluster is pinged every 30sec."
                  >
                    <span className={classes.textCss}>{clusterTimestamp}</span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container spacing={3} >
                <Grid item xs={9}>
                  <span className={classes.textCss}>Worker pod replicas:</span>
                </Grid>
                <Grid item xs={3} className={classes.gridAlign} >
                  <span className={classes.textCss}>
                  {clusterStatus ===  true ? (workerPodReplicas) : ('Offline')}
                    </span>
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default withFirebase(PopoverPopupState);
