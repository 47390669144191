import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class HorizontalbarChart extends React.Component {
  render() {
    return (
      <div>
        <Chart
          width={"100%"}
          height={"300px"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "1 Thread", "2 Threads", "4 Threads", "8 Threads", "16 Threads"],

            ["Desktop", 143.58, 71.91, 37.43, 21.60, 13.55],
            ["Laptop", 206.73, 123.73, 78.11, 49.99, 56.74],
            ["Raspberry Pi", 1334.67, 668.01, 362.04, 356.75, 354.39],
          ]}
          options={{
            title: "8000 million iterations single Node Multi-threaded performance",
            chartArea: { width: "70%", height: "65%" },
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            hAxis: {
              title: "Execution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
