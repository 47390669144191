export const DOCUMENTATION = '/documentation'
export const INTRODUCTION = DOCUMENTATION + '/introduction';
export const EXAMPLES = DOCUMENTATION+ '/examples'
export const HOWITWORKS = DOCUMENTATION + '/how-it-works'
export const GETTINGSTARTED = DOCUMENTATION+ '/getting-started'
export const INSTALLATION =DOCUMENTATION + '/getting-started/installation'
export const IMPLEMENTATION = DOCUMENTATION+ '/getting-started/implementation'
export const LANDINGPAGE = '/';
export const CREATEACCOUNT = '/create-account';
export const APIKEY = '/api-key'
export const PROOFOFWORK = '/proof-of-work'

export const MANAGEACCOUNT = '/manage-account';
export const ACCOUNTOVERVIEW = MANAGEACCOUNT + '/account-overview';
export const CHANGEPASSWORD = MANAGEACCOUNT + '/change-password';
