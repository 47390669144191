import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class BruteForceNumber1 extends React.Component {
  render() {
    return (
      <div>
        <Chart
          width={"100%"}
          height={"100%"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "74 pods/tasks"],
           
            ["Laptop", 595.39],
            ["Raspberry Pi Cluster", 362.48],
            ["Desktop", 108.92 ],
          ]}
          options={{
            title: "Kubernetes cluster vs. single machine (CPU)",
            chartArea: { width: "60%", height: "40%" },
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            hAxis: {
              title: "Execution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}