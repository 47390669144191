import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
var bcrypt = require('bcryptjs');
// Required for side-effects
require("firebase/firestore");

var firebaseConfig = {
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};
// Initialize Cloud Firestore through Firebase


// https://firebase.google.com/docs/auth/web/start
class Firebase {
  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.auth = firebase.auth();
    this.displayName = "";
    this.isLoggedIn = this.auth.currentUser !== null;
    this.fetchKeyIfLoggedIn()
    this.statusDoc = this.db.collection('clusterStatus').doc('clusterStatus');
    this.auth.onAuthStateChanged(user => {
      if (user) {
        localStorage.setItem('currentUser', user.email);
        if(user.displayName){
          this.displayName = user.displayName;
          this.emailAddress = user.email;
        }
        this.userDoc = this.db.collection('Users').doc(user.email);
        this.fetchUserAPIKey(user.email).then((res) => {
          this.apiKey = res.APIKey;
          this.callsLeft = res.CallsLeft;
          this.callsDoc = this.db.collection('APIkeys').doc(this.apiKey).collection("Calls");
        });
        this.isLoggedIn = true;
      } else {
        localStorage.setItem('currentUser', "");
        this.isLoggedIn = false;
      }
    });
  }

  getClusterStatus = async () => {
    return await this.statusDoc.get();
  }

  deleteAccount = () => {
    this.auth.currentUser.delete().then(function(user) {
      console.log("Deleted user : " , user)
    }).catch(function(error) {
      console.log("Error deleting user: : " , error)
    });
  }

  fetchKeyIfLoggedIn = async() => {
    if(localStorage.getItem('currentUser')){
      await this.fetchUserAPIKey(localStorage.getItem('currentUser')).then((res) => {
       this.apiKey = res.APIKey;
       this.callsLeft = res.CallsLeft;
       // this.callsDoc = this.db.collection('APIkeys').doc(this.apiKey.toString());
       this.callsDoc = this.db.collection('APIkeys').doc(this.apiKey).collection("Calls");
     });
   }
  }
  
  renewUserAPIKey = async (keyCalls) => {
    // Create an initial document to update.
    var userAPIKeyRef = this.db
      .collection("Users")
      .doc(this.auth.currentUser.email);
    try {
      const res = await userAPIKeyRef.update({
        CallsLeft: keyCalls
      });
      return res;
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  replaceUserAPIKey = async (keyCalls) => {
    // Create an initial document to update.
    var userAPIKeyRef = this.db
      .collection("Users")
      .doc(this.auth.currentUser.email);
    var apiKeyRef = this.db
      .collection("APIkeys")
      .doc(this.apiKey);

    //DELETION OF OLD KEY
    apiKeyRef.collection("Calls").get().then(res => {
      res.forEach(element => {
        apiKeyRef.collection("Calls").doc(element.id).delete().then(() => console.log("DELETED DOCUMENT!"))
    });
    }) 
    await apiKeyRef.delete().then((deleteResult) => {console.log("DEL#ETE COLLECTION", deleteResult)});

    try {
      var time = new Date().toString()
      var hash = bcrypt.hashSync(time, 8);
      hash = hash.replace(/\//g, '');
      const res = await userAPIKeyRef.update({
        APIKey: hash,
        CallsLeft: keyCalls
      });
      this.apiKey = hash;
      this.callsDoc = this.db.collection('APIkeys').doc(this.apiKey).collection("Calls");

      await this.db.collection('APIkeys').doc(this.apiKey).collection("Calls").add({
        dummy : true
      }).then((res) => {

      }) 
      return res;
    } catch (e) {
      console.error("Error updating document: ", e);
    }
  };

  signUpWithEmailAndPassword = (email, password, displayNameUser) => {
    return this.auth
      .createUserWithEmailAndPassword(email, password);
  };
  fetchUserAPIKey = async (email) => {
    if (!email) return;

    try {
      const querySnapshot = await this.db.collection("Users").get();
      let apikeyData;

      querySnapshot.forEach((doc) => {
        if (doc.id === email.toLowerCase()) {

          apikeyData = doc.data();
        }
      });
      return apikeyData;
    } catch (e) {
      console.error("Uncaught error", e);
    }
  };

  signInWithEmailAndPassword = async (email, password) => {
    try {
      const res = this.auth.signInWithEmailAndPassword(email, password).then((res) => {
          this.displayName = res.user.displayName;
          this.email = res.user.email;
      });
      this.fetchUserAPIKey(email);

      return res;
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === "auth/wrong-password") {
        alert("Wrong password.");
      } else {
        alert(errorMessage);
      }
      console.log(error);
    }
  };

  // https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial
  doSignOut = () => {
    this.auth.signOut();
  };
  doPasswordReset = email => {
    return this.auth.sendPasswordResetEmail(email);
  };
  doPasswordUpdate = password => {
    return this.auth.currentUser.updatePassword(password);
  };
}

export default Firebase;
