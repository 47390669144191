import React from "react";
// https://reactjs.org/docs/typechecking-with-proptypes.html
import PropTypes from "prop-types";
// https://material-ui.com/
import { TextField } from "@material-ui/core";

const TextFieldWithPattern = ({ pattern, checkifcanpresscreateaccount, reset, ...props }) => {
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);

  const handleChange = e => {
    const input = e.target.value;
    // Do validation
    if (!pattern.test(input) && input !== "") {
      setError(true);
      console.log("setting to false")
      checkifcanpresscreateaccount(false)
    } else {
      setError(false);
      console.log("setting to true")

      checkifcanpresscreateaccount(true)
    }
    setValue(input);
  };

  return <TextField helperText={error ? "Email is not a valid email" : ""} error={error} onChange={handleChange} value={value} {...props} />;
};

TextFieldWithPattern.propTypes = {
  pattern: PropTypes.instanceOf(RegExp).isRequired,
  checkifcanpresscreateaccount: PropTypes.func
};

export default TextFieldWithPattern;
