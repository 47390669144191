// https://material-ui.com/
import { makeStyles } from "@material-ui/core/";
// https://github.com/iamhosseindhv/notistack
import { withSnackbar } from 'notistack';
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { BrowserRouter as Router, Route } from "react-router-dom";
import Account from "../accountComponent/Account";
import CreateAccount from "../createAccountComponent/CreateAccount";
import Documentation from "../documentation/Documentation.jsx";
import Header from "../header/Header";
import LandingPage from "../landingPageComponent/LandingPage.jsx";
import LoginDialog from "../loginComponent/LoginDialog";
import ProofOfWork from "../proofOfWorkComponent/ProofOfWork";
import * as ROUTES from '../routingConstants';
import ApikeyData from './../apikeyComponent/ApikeyData';
import './Home.scss';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxWidth: "85%",
    margin: "auto",
    position: 'relative',
  }
}));

const Home = () => {
  const classes = useStyles();
  const [open, setDialogOpen] = React.useState(false);
  return (
    <div className="home">
    <Router>
      <div className={classes.root}>
        <Header setDialogOpen={setDialogOpen} />
        <Route exact path={ROUTES.LANDINGPAGE} component={LandingPage} />
        <Route path={ROUTES.DOCUMENTATION} component={() => <Documentation ></Documentation>} />
        <Route path={ROUTES.PROOFOFWORK} component={() => <ProofOfWork></ProofOfWork>} />
        <Route path={ROUTES.MANAGEACCOUNT} component={() => <Account></Account>} />
        <Route path={ROUTES.CREATEACCOUNT} component={() => <CreateAccount ></CreateAccount>}/>   
        <Route path={ROUTES.APIKEY} component={ApikeyData}/>
      </div>
    <LoginDialog open={open} setDialogOpen={setDialogOpen} ></LoginDialog>
    </Router>
    </div>
  );
}

export default withSnackbar(Home);