// https://material-ui.com/
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Fingerprint, Visibility, VisibilityOff } from "@material-ui/icons";
// https://www.npmjs.com/package/clsx
import clsx from "clsx";
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { Route, Switch, useHistory } from 'react-router-dom';
import { withFirebase } from "../Firebase/context";
import * as ROUTES from '../routingConstants';
import "./Account.scss";
import AccountOverview from "./AccountOverview";
import DeleteAccountDialog from "./DeleteAccountDialog";
// https://github.com/iamhosseindhv/notistack
import { withSnackbar  } from 'notistack';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 500,
    margin: "auto",
    width: "92%",
    paddingTop: "10px"
  },
  insider: {
    width: "80%",
  },
  rowContainer: {
    paddingBottom: "12px"
  },
  SelectNumber: {
    paddingTop: "30px"
  },
  marginChangePassword: {
    margin: theme.spacing(1)
  },
  withoutLabelChangePassword: {
    marginTop: theme.spacing(3)
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: "100%"
  },
  textField: {
    display: "Flex",
    padding: "24px",
    width: "65%"
  },
  test: {
    padding: "24px"
  }
}));

function Account(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = React.useState(false);

  const history = useHistory();

  const openDeleteAccSnackBar = () => {
    props.enqueueSnackbar('Your account has been deleted.', {variant: 'success', autoHideDuration: 1500,} );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const deleteAcc = () => {
    props.firebase.deleteAccount();
    openDeleteAccSnackBar()
    history.push(ROUTES.LANDINGPAGE)
  }

  const handleCloseReplaceAPIKeyDialog = () => {
    setOpenDeleteAccountDialog(false);
    setValue(0)
    history.push(ROUTES.ACCOUNTOVERVIEW)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Account overview"  onClick={() => { history.push(ROUTES.ACCOUNTOVERVIEW) }} />
        <Tab label="Change password"  onClick={() => { history.push(ROUTES.CHANGEPASSWORD) }}/>
        <Tab label="Delete account" onClick={() => setOpenDeleteAccountDialog(true)} />
      </Tabs>
      <div className={classes.insider}>
      <Switch>
          <Route exact path={ROUTES.MANAGEACCOUNT} component={() => <AccountOverview firebase={props.firebase}></AccountOverview>} />
          <Route exact path={ROUTES.ACCOUNTOVERVIEW} component={() => <AccountOverview firebase={props.firebase}></AccountOverview>} />
          <Route path={ROUTES.CHANGEPASSWORD} component={() => <ChangePassword firebase={props.firebase}></ChangePassword>} />
      </Switch>
      </div>

      <DeleteAccountDialog open={openDeleteAccountDialog} deleteAccount={() => deleteAcc()} handleClose={() => handleCloseReplaceAPIKeyDialog()}></DeleteAccountDialog>
    </div>
  );
}
export default withSnackbar(withFirebase(Account));

const ChangePassword = () => {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    amount: "",
    oldPassword: "",
    newPassword: "",
    retypeNewPassword: "",
    weight: "",
    weightRange: "",
    showOldPassword: false,
    showNewPassword: false,
    showRetypeNewPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };
  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };
  const handleClickShowRetypeNewPassword = () => {
    setValues({
      ...values,
      showRetypeNewPassword: !values.showRetypeNewPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className={classes.insider}>
      <div className="apikeyWrapperPasswordChange">
        <FormControl fullwidth="true" className={clsx(classes.textField)}>
          <InputLabel htmlFor="standard-adornment-password">
            Current password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showOldPassword ? "text" : "password"}
            value={values.oldPassword}
            onChange={handleChange("oldPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className="apikeyWrapperPasswordChange">
        <FormControl fullwidth="true" className={clsx(classes.textField)}>
          <InputLabel htmlFor="standard-adornment-password">
            New password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showNewPassword ? "text" : "password"}
            value={values.newPassword}
            onChange={handleChange("newPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <div className="apikeyWrapperPasswordChange">
        <FormControl fullwidth="true" className={clsx(classes.textField)}>
          <InputLabel htmlFor="standard-adornment-password">
            Retype new password
          </InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showRetypeNewPassword ? "text" : "password"}
            value={values.retypeNewPassword}
            onChange={handleChange("retypeNewPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRetypeNewPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showRetypeNewPassword ? (
                    <Visibility />
                  ) : (
                      <VisibilityOff />
                    )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button
          className="apiButton"
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Fingerprint />}
        >
          Change password
        </Button>
      </div>
    </div>
  );
};
