import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class KubernetesOverhead extends React.Component {
  render() {
    return (
      <div>
        <Chart
          width={"100%"}
          height={"100%"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "1 Thread", "2 Threads", "4 Threads"],

            ["W/ Docker, Kubernetes and CSPL", 1365.89, 684.80, 419.85],
            ["w/ Docker", 1370.70, 683.97, 396.28],
          ]}
          options={{
            title: "8000 million iterations, 1 Raspberry Pi 3B, Kubernetes and CSPL overhead",
            chartArea: { width: "70%", height: "60%" },
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            hAxis: {
              title: "Execution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
