// https://material-ui.com/
import { Typography } from '@material-ui/core';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CallSplitIcon from '@material-ui/icons/CallSplit';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import React, { useRef } from 'react';
// https://www.npmjs.com/package/react-embed-gist
import ReactEmbedGist from "react-embed-gist";
// https://reacttraining.com/react-router/web/guides/quick-start
import { useHistory } from "react-router-dom";
import * as ROUTES from '../../../routingConstants';
import './Examples.scss';

function scrollToElement(ref) {
    var topPos = document.getElementById(ref.current.id).offsetTop;
    document.getElementById('documentationContent').scrollTop = topPos - 75;
}

const Examples = () => {
    const [dense] = React.useState(true);
    const history = useHistory();
    
    function handleGetStartedClick() {
        history.push(ROUTES.IMPLEMENTATION);
    }

    const mySplitRef = useRef(null)
    const myCollectRef = useRef(null)
    const myParallelRef = useRef(null)

    const handleScroll = (ref) => {
        scrollToElement(ref)
    };

    return (
        <div>
            <div className='documentationContent' id="documentationContent">
                <h1>Examples</h1>
                <Typography>This page will show an example of how to implement the interface, including discribing text of
            what is done and why. For an explanation of the methods in the interface please visit <a  onClick={handleGetStartedClick}>Getting started tab</a>.</Typography>
                <Typography>The following example is a pi-approximation using the monte carlo method which is a method that
            "generates a large number of random points and see how many fall in the circle enclosed by the unit square". It is described <a href="https://www.101computing.net/estimating-pi-using-the-monte-carlo-method/">here</a>. </Typography>

                <Typography>The interface consist of three functions, whereas two of them must be implemented.</Typography>
                <List dense={dense}>
                    <ListItem>
                        <ListItemIcon>
                            <CallSplitIcon className="iconSize" />
                        </ListItemIcon>
                        <ListItem
                            onClick={() => handleScroll(mySplitRef)}
                            button
                            component="a"
                        >
                            <ListItemText
                                primary={
                                    <Typography type="body2" style={{ color: "#083d8d" }}>Split</Typography>
                                }
                                secondary="Link to split"
                            />
                        </ListItem>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FormatAlignJustifyIcon className="iconSize" />
                        </ListItemIcon>
                        <ListItem
                            button
                            component="a"
                            onClick={() => handleScroll(myParallelRef)}
                        >
                            <ListItemText
                                primary={
                                    <Typography type="body2" style={{ color: "#083d8d" }}>
                                        Parallel
                  </Typography>
                                }
                                secondary="Link to parallel"
                            />
                        </ListItem>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <TimelapseIcon className="iconSize" />
                        </ListItemIcon>
                        <ListItem
                            button
                            component="a"
                            onClick={() => handleScroll(myCollectRef)}
                        >
                            <ListItemText
                                primary={
                                    <Typography type="body2" style={{ color: "#083d8d" }}>
                                        Collect
                  </Typography>
                                }
                                secondary="Links collect"
                            />
                        </ListItem>
                    </ListItem>
                </List>
                <h3 id="split" ref={mySplitRef}>Split</h3>

                <Typography>Split is the method where we implement how the datasource (if any) should be split up, and very importantly, how many workers is wanted on the cluster.
                In the situation where no datasource is needed, one still has to implement functionality to return a list of the length of requested workers.
        </Typography>
                <p></p>
                <Typography>In the example below, there are no real datasource so we use the parameter to specify how many iterations of the pi-approximation we want in total.
        </Typography>
                <ul>
                    <ReactEmbedGist gist="/AndreasSn/bdf0cfed7275b09a8c1c44764ce10114"></ReactEmbedGist>
                </ul>
                <h3 id="parallel" ref={myParallelRef}>Parallel</h3>
                <Typography>Parallel is the actual function/algoritm that will run on each node on the cluster. So in this example, the parallel implementation is
                the logic for the pi-approximation algorithm.
        </Typography>
                <ul>
                    <ReactEmbedGist gist="AndreasSn/258d63c784ec0d9d9bfc095229d27956"></ReactEmbedGist>
                </ul>
                <h3 id="collect" ref={myCollectRef}>Collect</h3>
                <Typography> Collect should define how all the subresults from parallel should be gathered together. Here the implementation is simply
                adding up all the occurences of points inside the circle from the parallel function.
        </Typography>
                <ul>
                    <ReactEmbedGist gist="AndreasSn/98fbea4d1e0717208b2ed4b35491153e"></ReactEmbedGist>
                </ul>


            </div>
        </div>);


}

export default Examples;