import React from 'react';
// https://material-ui.com/
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, Role, pcs, Cost, OS, CPU, Cores, MHz) {
  return { name, Role, pcs, Cost, OS, CPU, Cores, MHz };
}

const rows = [
  createData('Desktop', "Tester", 1, "2.083 kr.", "Windows 10" , "64-bit AMD Ryzen 7 2700X", 8, "3.800 Mhz "),
  createData('Laptop Lenovo T480s' , "Tester", 1 , "10.000kr.", "Windows 10", "64-bit Intel i7-8550U" , 4, "1.990 Mhz"),
];

export default function DenseTable() {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table" style={{width: "1100px"}}>
        <TableHead>
          <TableRow>
            <TableCell>Platform (device)</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Pcs.</TableCell>
            <TableCell align="right">Cost pr. pcs.</TableCell>
            <TableCell align="right">OS</TableCell>
            <TableCell align="right">CPU</TableCell>
            <TableCell align="right">Cores</TableCell>
            <TableCell align="right">MHz</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.Role}</TableCell>
              <TableCell align="right">{row.pcs}</TableCell>
              <TableCell align="right">{row.Cost}</TableCell>
              <TableCell align="right">{row.OS}</TableCell>
              <TableCell align="right">{row.CPU}</TableCell>
              <TableCell align="right">{row.Cores}</TableCell>
              <TableCell align="right">{row.MHz}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}