// https://material-ui.com/
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import AccountCircle from '@material-ui/icons/AccountCircle';
// https://github.com/iamhosseindhv/notistack
import { withSnackbar } from 'notistack';
import React, { Component } from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase/context';
import * as ROUTES from '../routingConstants';
import TextFieldWithPattern from './../forms/TextFieldWithPattern';
import './CreateAccount.scss';

const INITIAL_STATE = {
  email: "",
  password: "",
  passwordRepeat: "",
  displayName: "",
  open: false,
  passwordOk: null,
  canPressCreateAccount : false,
};

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.createAccount(e)
    }
  }

  createAccount = (event) => {
    event.preventDefault();
    this.props.firebase
      .signUpWithEmailAndPassword(
        this.state.email,
        this.state.password,
        this.state.displayName
      )
      .then((res) => {
        this.props.firebase.displayName = this.state.displayName;
        this.setState({ INITIAL_STATE });
        if (res.user) {
          res.user.updateProfile({
            displayName: this.state.displayName
          }).then(res => { this.displayName = res });
          this.props.firebase.db
            .collection("Users")
            .doc(res.user.email)
            .set({
              APIKey: "No API Key purchased...",
              CallsLeft: 0
            });
          this.props.history.push(ROUTES.INTRODUCTION);
        }
      })
      .catch((err) => {
        if(err.code === "auth/email-already-in-use"){
          this.openEmailInUseSnackBar(err.message);
        } else if (err.code === "auth/weak-password"){
          this.openWeakPasswordSnackbar(err.message);
        }
        this.setState({
          password: "",
          passwordRepeat: "",
          passwordOk: null,
        });
        console.log("something went wrong while creating account", err);
      });
  };

  openEmailInUseSnackBar = (msg) => {
    this.props.enqueueSnackbar(msg, {variant: 'error', autoHideDuration: 2000,} );
  };
  openWeakPasswordSnackbar = (msg) => {
    this.props.enqueueSnackbar(msg, {variant: 'error', autoHideDuration: 2000,} );
  };

  shouldResetPasswordError = () => {
    return (
      (this.state.passwordRepeat === "" && this.state.password === "") ||
      this.state.passwordRepeat === ""
    );
  };

  changeState = (event) => {

    this.setState({ [event.target.name]: event.target.value }, () => {
      if (this.shouldResetPasswordError()) {
        this.setState({ passwordOk: null });
      }
    });
    console.log(this.state)

  };
  onPasswordChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.setState({
        passwordOk: this.state.password === this.state.passwordRepeat,
      });
      if (this.shouldResetPasswordError()) {
        this.setState({ passwordOk: null });
      }
    });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  checkIfCanPressCreateAccount = (value) => {
    console.log("Setting state to ", value)
    this.setState({canPressCreateAccount: value})
  } 
  

  render() {
    return (
      <div className="LeagueOfLegends">
        <div className="title">Create your CSPL account.</div>
        <div>
          <form className="form" noValidate onKeyDown={this._handleKeyDown}>
            <TextFieldWithPattern
              pattern={/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              autoComplete="new-password"
              onBlur={this.changeState}
              checkifcanpresscreateaccount={() => this.checkIfCanPressCreateAccount()}
            />
            <TextField
              error={
                this.state.passwordOk === null || this.state.passwordOk
                  ? false
                  : true
              }
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={this.state.password}
              onChange={this.changeState}
              autoComplete="new-password"
            />
            <TextField
              margin="normal"
              error={
                this.state.passwordOk === null || this.state.passwordOk
                  ? false
                  : true
              }
              required
              fullWidth
              name="passwordRepeat"
              label="Repeat password"
              type="password"
              id="passwordRepeat"
              value={this.state.passwordRepeat}
              autoComplete="new-password"
              helperText={
                this.state.passwordOk === null || this.state.passwordOk
                  ? ""
                  : "passwords not matching."
              }
              onChange={this.onPasswordChange}
            />
            <Grid
              container
              spacing={1}
              style={({ paddingTop: "10px"})}
              alignItems="flex-end"
            >
              <Grid item>
                <AccountCircle />
              </Grid>
              <Grid item style={{width: "300px"}}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="displayName"
                  label="Display name "
                  type="text"
                  id="displayName"
                  onChange={this.changeState}
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="submit"
                  onClick={this.createAccount}
                  disabled={
                    this.state.email === (null || "") ||
                    this.state.password === (null || "") ||
                    this.state.passwordRepeat === (null || "") ||
                    this.state.displayName === (null ||"")  || this.state.canPressCreateAccount === false
                  }
                >
                  Create account
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(withFirebase(withSnackbar(CreateAccount)));
