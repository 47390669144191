import React from "react";
import howItWorkImage1 from '../../../../images/howitworks1.png'
import howItWorkImage2 from '../../../../images/howitworks2.png'
// https://material-ui.com/
import { Typography } from "@material-ui/core";
import "./HowItWorks.scss"

export default function HowItWorks({ drawer }) {
    return (
        <div>
            <div className='documentationContent'>
                <h1>How the system works</h1>
                <Typography>After calling the Run() method on an ClusterRunner object the class implementation is sent to the cluster.
                    The master node will first of all make a check, to see if the provided api-key is valid. This will also decrement the calls-left attribute for the calling user. 
                    Afterwards it will call the implemented Split() method. 
                    Depending on the number of elements in the list returned by split, it will request that same number of workers in the cluster and delegate 
                    a chunk of the datasource to each one of them.   
                </Typography>
                <div className="centerImage">
                    <img className="imageCss" alt="" src={howItWorkImage1}></img>
                </div>
                <p></p>
                <Typography>Each worker will then call the Parallel() function with the provided part of the datasource. As soon as they are done
                     they will send back the result to the master node. The master waits for all the workers to be done and then calls the Collect() method. 
                </Typography>
                <div className="centerImage">
                    <img className="imageCss" alt="" src={howItWorkImage2}></img>
                </div>
                <p></p>
                <Typography>As soon as collect is done, the result will be sent back to you, the client.</Typography>
            </div>
        </div>
    )

}