// https://material-ui.com/
import { DialogActions } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import 'firebase/functions';
// https://github.com/iamhosseindhv/notistack
import { withSnackbar } from 'notistack';

// https://reactjs.org/docs/typechecking-with-proptypes.html
import PropTypes from 'prop-types';

import React, { Component } from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../routingConstants';
import TextFieldWithPattern from './../forms/TextFieldWithPattern';
import './Login.scss';

const INITIAL_STATE = {
  email: '',
  password: '',
  openWrongUsername: false,
  openWrongPassword: false,
};

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ openWrongUsername: false, openWrongPassword: false });
  };

  login = (event) => {
    event.preventDefault();
    this.props.firebase.signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
      this.setState({ INITIAL_STATE });
      this.setState({ open: true });
      this.openSuccessSnackbar()
      this.props.setDialogOpen(false);
      this.props.history.push(ROUTES.LANDINGPAGE)
    }).catch((err) => {
      console.log("Something went wrong", err);
      if (err.code === "auth/user-not-found") {
        this.openErrorUsernameSnackbar()
      } else if (err.code === "auth/wrong-password") {
        this.openErrorWrongPasswordSnackbar()

      }
    })
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.login(e)
    }
  }

  // https://github.com/iamhosseindhv/notistack
  openSuccessSnackbar = () => {
    this.props.enqueueSnackbar('You logged in!', { variant: 'success', autoHideDuration: 1500, });
  };
  openErrorWrongPasswordSnackbar = () => {
    this.props.enqueueSnackbar('Wrong password', { variant: 'error', autoHideDuration: 1500, });
  };
  openErrorUsernameSnackbar = () => {
    this.props.enqueueSnackbar('Wrong username', { variant: 'error', autoHideDuration: 1500, });
  };
  openPasswordSentSnackbar = (email) => {
    this.props.enqueueSnackbar('An email with reset password link was sent to ' + email, { variant: 'info', autoHideDuration: 2500, });
  };
  openNeedEmailAddressSnackbar = () => {
    this.props.enqueueSnackbar('Please provide an email', { variant: 'error', autoHideDuration: 1500, });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  forgotPassword = (email) => {
    console.log(this.props.firebase.firestoreEmailFunc)
    if (email) {
      this.props.firebase.doPasswordReset(this.state.email).then(() => {
        this.openPasswordSentSnackbar(this.state.email);
      }).catch((error) => {
        console.log("ERROR", error)
      })
    } else {
      this.openNeedEmailAddressSnackbar()
    }
  }

  render() {
    return (
      <div>
        <Container component="main" maxWidth="xs">
          <Typography className="paddingTop" component="h1" variant="h5">
            <LockOpenIcon className="iconAlignment"></LockOpenIcon> Log into your CSPL account.
        </Typography>
          <form className="form" noValidate onKeyDown={this._handleKeyDown}>
            <TextFieldWithPattern
              pattern={/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={this.onChange}
              autoFocus
              value={this.state.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={this.onChange}
              autoComplete="current-password"
              value={this.state.password}
            />
          </form>
          <DialogActions>
            <Button onClick={() => this.forgotPassword(this.state.email)} color="primary">
              Forgot password
            </Button>
            <Button onClick={() => this.props.setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.login} color="primary" disabled={this.state.password === "" || this.state.email === ""}>
              Sign in
            </Button>
          </DialogActions>
        </Container>
      </div>
    );
  }
}
const SignInForm = withRouter(withSnackbar(withFirebase(SignIn)));

export default function Login({
  setDialogOpen,
}) {
  return (
    <div className="root">
      <SignInForm setDialogOpen={setDialogOpen}></SignInForm>
    </div>
  );
}

Login.propTypes = {
  setDialogOpen: PropTypes.any.isRequired,
};

