import React from 'react';
// https://material-ui.com/
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
const useStyles = makeStyles({
    divForContent: {
        width: "600px",
  },
});


export default function DenseTable() {
  const classes = useStyles();

  return (

    <div className={classes.divForContent}>
        <span>Throughout the tests, you will notice pods and threads being matched. These
            two expressions are interchangeable, meaning they mean the same, but are named differently
            due to Kubernetes having spawned 1 pod per CPU core. Therefor:
        </span>
        <br></br>
        <Chip style={{marginTop: "10px", marginBottom: "10px"}} label="1 Pod = 1 Thread" variant="outlined" />
        <br></br>
        <span>Furthermore, if not written explicitly, the tests are conducted with Raspberry Pi 3B devices and not
            with a Raspberry Pi 3B+.
        </span>
    </div>
  );
}