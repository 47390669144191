// https://material-ui.com/
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import React from "react";
// https://reacttraining.com/react-router/web/guides/quick-start
import { Link, useHistory } from "react-router-dom";
import "./BreadcrumbsView.scss";
import ListAltIcon from "@material-ui/icons/ListAlt";
import BuildIcon from "@material-ui/icons/Build";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import PersonAddIcon from '@material-ui/icons/PersonAdd';



const ActiveLastBreadcrumb = ({ item }) => {
  const history = useHistory();
  let pathLink = [];

  const pathnames = history.location.pathname.split("/");
  pathnames.shift();
  return (
    <div className="breadcrumbDiv">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {pathnames.map((pathname, index) => {
          for (var i = 0; i <= index; i++) {
            pathLink[i] = pathnames[i];
          }
          // Klamt hack
          if (index + 1 === pathnames.length) {
            return (
              <Typography key={index} className="lastElement">
                {IconMapper(pathname)}
                {pathname}
              </Typography>
            );
          } else
            return (
              <Link
                key={index}
                replace
                className="breadCrumbLink"
                color="inherit"
                to={"/" + pathLink.toString().replace(/,/g, "/")}
              >
                {IconMapper(pathname)}
                {pathname}
              </Link>
            );
        })}
      </Breadcrumbs>
    </div>
  );
};

const IconMapper = (path) => {
  switch (path) {
    case "documentation":
      return (
        <ListAltIcon className="breadCrumbIcon"
        ></ListAltIcon>
      );
    case "proof-of-work":
      return (
        <PermIdentityIcon className="breadCrumbIcon"
        ></PermIdentityIcon>
      );
    case "api-key":
      return (
        <FingerprintIcon className="breadCrumbIcon"
        ></FingerprintIcon>
      );
    case "manage-account":
      return (
        <BuildIcon className="breadCrumbIcon"
        ></BuildIcon>
      );
      case "create-account":
      return (
        <PersonAddIcon
          style={{ fontSize: "1em", marginRight: "3px", verticalAlign: "middle" }}
        ></PersonAddIcon>
      );
    default:
      return;
  }
};

export default ActiveLastBreadcrumb;
