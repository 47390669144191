import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class HorizontalChartClusterLinear extends React.Component {
  render() {
    return (
      <div>
      
        <Chart
          width={"100%"}
          height={"250px"}
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["x", "Actual-8B.", "Actual-100B.", "Theoretical"],
            [1, 1, 1, 1],
            [2, 1.84, 2.00, 2],
            [4, 3.65, 3.94, 4],
            [8, 7.11, 7.76, 8],
            [16, 13, 15.25,16],
            [32, 23.3, 30.30, 32],
            [64, 38.15, 50.67, 64],
            [80, 42.19, 64.38, 80],

          ]}
          options={{
            pointSize: 5,
            // colors: ['#563f46', '#8ca3a3','#c6bcb6', '#484f4f' ],
            colors: ['#5b9aa0', '#b8a9c9','#622569', '#588c7e' ],
            title: "8000- and 100000 million iterations, showing actual gains factor vs. theoretical",
            chartArea: { width: "70%", height: "65%" },
            hAxis: {
              title: "Pods",
            },
            vAxis: {
              title: "Gain factor",
            },
          }}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
