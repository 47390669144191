import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class LineChart extends React.Component {
  render() {
    return (
      <div>
        <Chart
          width={"100%"}
          height={"100%"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "1 Thread", "2 Threads", "4 Threads", "20 Pods"],
            ["Raspberry Pi 3B", 1370.69, 683.96, 396.28, 89.92],
            ["Raspberry Pi 3B+", 1169.53, 630.56, 354.82 ,70.23],
          ]}
          options={{
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            title: "8000 million iterations. Raspberry Pi 3B vs. Raspberry Pi 3B+",
            chartArea: { width: "70%", height: "60%" },
            hAxis: {
              title: "Exectution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div> 
    );
  }
}
