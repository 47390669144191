// https://material-ui.com/
import { Button, Chip, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { AccountCircle, VpnKey } from "@material-ui/icons";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
// https://github.com/iamhosseindhv/notistack
import { withSnackbar } from 'notistack';
import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { withFirebase } from "../Firebase/context";
import "./Account.scss";
import AccountDialog from "./AccountDialog";
import ReplaceAPIKeyDialog from "./ReplaceAPIKeyDialog";

const useStyles = makeStyles(theme => ({
  insider: {
    width: "95%"
  },
  textField: {
    padding: "24px",
    marginRight: "14px",
    width: "70%"
  }
}));

const AccountOverview = ( { firebase, enqueueSnackbar }) => {
  var apiKeyMessageForUser = "";
  const checkIfPurchased = () => {

    if (apiKey === "No API Key purchased...") {
      apiKeyMessageForUser = "Buy API key";
    } else {
      apiKeyMessageForUser = "Renew your API Key";
    }
  };
  const classes = useStyles();
  const [openAPIKeyDialog, setOpenAPIKeyDialog] = React.useState(false);
  const [openReplaceAPIKeyDialog, setOpenReplaceAPIKeyDialog] = React.useState(false);
  const [callsLeft, setCallsLeft] = React.useState(0);
  const [apiKey, setUserApiKey] = React.useState(0);
  const [emailAddress, setEmailAddress] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");

  checkIfPurchased();

  useEffect(() => {
    if(firebase.userDoc){
      var unsubscribe  = firebase.userDoc.onSnapshot(snapshot => { 
        Promise.resolve().then(() => {
          ReactDOM.unstable_batchedUpdates(() => {
            setDisplayName(firebase.displayName);
            setEmailAddress(firebase.emailAddress);
            setCallsLeft(snapshot.data().CallsLeft);
            setUserApiKey(snapshot.data().APIKey);
          });
        });
      });
      return unsubscribe;
    }
  }, [])

  const handleClickOpenAPIKeyDialog = () => {
    setOpenAPIKeyDialog(true);
  };

  const handleClickOpenReplaceAPIKeyDialog = () => {
    setOpenReplaceAPIKeyDialog(true);
  };

  const handleCloseAPIKeyDialog = () => {
    setOpenAPIKeyDialog(false);
  }

  const handleCloseReplaceAPIKeyDialog = () => {
    setOpenReplaceAPIKeyDialog(false);
  }

  const openApiKeySuccesSnackBar = () => {
    enqueueSnackbar('Your API-Key has been changed.', {variant: 'info', autoHideDuration: 1500,} );
  };
  const openApiKeyPurchaseSuccess = () => {
    enqueueSnackbar('Your API-Key has been purchased.', {variant: 'success', autoHideDuration: 1500,} );
  };
  const openApiKeyRenewSuccess = () => {
    enqueueSnackbar('Your API-Key has been renewed.', {variant: 'success', autoHideDuration: 1500,} );
  };

  return (
    <div className={classes.insider}>
      <div className="apikeyWrapper">
        <TextField
          disabled
          className={classes.textField}
          id="userDisplayName"
          label="Username"
          value={displayName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle></AccountCircle>
              </InputAdornment>
            )
          }}
        />
      </div>
      <div className="apikeyWrapper">
        <TextField
          fullWidth
          disabled
          value={emailAddress}
          className={classes.textField}
          id="input-with-icon-textfield"
          label="Email address"
        />
      </div>
      <div className="apikeyWrapper">
        <TextField
          disabled
          fullWidth
          value={apiKey}
          className={classes.textField}
          id="userAPIKey"
          label="Your CSPL API Key:"
        />
        {/* <pre>{apiKey.APIKey}</pre>"Renew your API Key" */}
        <Tooltip title={"Replace current key"} aria-label="add">
          <IconButton
            className="removeApiKeyBtn"
            aria-label="delete"
            color="secondary"
            disabled={apiKey === "No API Key purchased..."}
            onClick={handleClickOpenReplaceAPIKeyDialog}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={apiKeyMessageForUser} aria-label="add">
          <Button
            className="apiButton"
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClickOpenAPIKeyDialog}
            startIcon={<VpnKey />}
          >
            {apiKeyMessageForUser}
          </Button>
        </Tooltip>
      </div>
      <div className="Chip">
        <Chip label={"Calls left: " + callsLeft} disabled variant="outlined" />
      </div>
      <ReplaceAPIKeyDialog
        open={openReplaceAPIKeyDialog}
        setOpenSnackBar={openApiKeySuccesSnackBar}
        userAPIKey={apiKey}
        handleClose={handleCloseReplaceAPIKeyDialog}
        callsLeft={callsLeft}
      />
      <AccountDialog
        setOpenSnackBarPurchase={openApiKeyPurchaseSuccess}
        setOpenSnackBarRenew={openApiKeyRenewSuccess}
        open={openAPIKeyDialog}
        userAPIKey={apiKey}
        handleClose={handleCloseAPIKeyDialog}
      />
    </div>
  );
};

export default withSnackbar(withFirebase(AccountOverview));
