// https://material-ui.com/
import { Dialog } from "@material-ui/core";
import React from 'react';
import Login from "../loginComponent/Login";

const LoginDialog = ({
    open,
    setDialogOpen,
}) => {
    return (
        <Dialog open={open}>
            <Login setDialogOpen={setDialogOpen}></Login>
        </Dialog>);
}

export default LoginDialog;