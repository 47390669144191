// https://material-ui.com/
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function Introduction(props) {
  window.scrollTo(0, 0);

  return (
    <div className="documentationContent">
      <h1>Introduction to Cluster supported parallel library</h1>
      <Typography>
        CSPL is a C# library for parallelizing tasks on a cluster of computers.
        The NuGet-package provide an interface to be implemented in order to get
        performance boost from the library. This documentation is a guide of how
        to use, implement and get most out of the NuGet-package cluster
        supported parallel library.
      </Typography>
      <p></p>
      <Typography>
        These documentations will give you an overview of CSPL and provide you
        with the information you need to get started, including working
        examples.{" "}
      </Typography>
      <Typography>
        It is assumed that you know of C#, as this is a documentation of CSPL
        and not C#. The NuGet package is written in .NET core and therefore can
        only be implemented with .NET core, as the program will run on a
        raspberry pi cluster.
      </Typography>
      <br></br>
      <h3>Video af CSPL</h3>
      <iframe
        width="100%"
        height="400"
        src="https://www.youtube.com/embed/BjVoVx_bv00"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
}
