// https://material-ui.com/
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
// https://github.com/iamhosseindhv/notistack
import { withSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { withFirebase } from "../Firebase/context";
import EnhancedTableHead from './TableHead';
import EnhancedTableToolbar from './TableToolBar';


// The layout and sorting functionality is highly inspired by https://material-ui.com/components/tables/#table

function createData(callId, numberOfNodes, startTime, endTime, executionTime, className, status, icon, queueNumber, label) {
    return { callId, numberOfNodes, startTime, endTime, executionTime, className, status, icon, queueNumber, label };
}

function descendingComparator(a, b, orderBy) {
    if (orderBy === "startTime" || orderBy === "endTime") {
        if (Date.parse(b[orderBy]) < Date.parse(a[orderBy])) {
            return -1;
        }
        if (Date.parse(b[orderBy]) > Date.parse(a[orderBy])) {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '92%',
        margin: "auto",
        flexShrink: 0,
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        flexShrink: 0,

    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    iconsComplete: {
        color: "#008b00",
    },
    iconsError: {
        color: "#E57373",
    },

    noMaxWidth: {
        fontSize: '12px',
        maxWidth: '1000px',
    },
    completed: {
        paddingRight: '8px'
    },
    lastCells: {
        minWidth: '5px',
        padding: '0px'
    },
    lastCellsIcon: {
        maxWidth: '2px',
        padding: '0px',
        paddingTop: '6px',
        minWidth: '3%',
    },
    innerDivTableBottom: {
        marginLeft: "auto",
        marginRight: "0"
    },

}));

const useStylesFacebook = makeStyles({
    iconsPending: {
        color: '#6798e5',
        animationDuration: '550ms',
        marginLeft: "3px",
        marginRight: "2px",
    },
});

function FacebookProgress(props) {
    const classes = useStylesFacebook();
    return (
        <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.iconsPending}
            size={18}
            thickness={4}
            {...props}
        />
    );
}



function EnhancedTable({ firebase, enqueueSnackbar }) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('startTime');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = React.useState([]);


    const openJobDoneSnackbar = () => {
        enqueueSnackbar('One job is one.', { variant: 'success', autoHideDuration: 1500, });
    };
    const openJobFailedSnackbar = () => {
        enqueueSnackbar('One job has failed.', { variant: 'error', autoHideDuration: 1500, });
    };

    const getFormattedDate = (dateToBeFormatted) => {
        var year = dateToBeFormatted.getFullYear();
        var month = months[dateToBeFormatted.getMonth()];
        var date = dateToBeFormatted.getDate();
        var hour = "0" + dateToBeFormatted.getHours();
        var min = "0" + dateToBeFormatted.getMinutes();
        var sec = "0" + dateToBeFormatted.getSeconds();
        return date + ' ' + month + ' ' + year + ' ' + hour.substr(-2) + ':' + min.substr(-2) + ':' + sec.substr(-2);
    }
    let unsubscribe;
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let rowsHolder = []
    const subscribeToApiCallsData = () => {
        if (firebase.callsDoc != null) {
            unsubscribe = firebase.callsDoc.onSnapshot(snapshot => {
                if (snapshot.size !== 0) {
                    snapshot.docChanges().forEach(change => {
                        if (!change.doc.data().dummy === true) {
                            if (change.type === "added") {
                                let startDate = new Date(change.doc.data().TimestampStart.seconds * 1000)
                                let endDate = "tbd.";
                                if (change.doc.data().TimestampEnd !== null) {
                                    endDate = getFormattedDate(new Date(change.doc.data().TimestampEnd.seconds * 1000))
                                }
                                console.log("ADDED", change.doc.data().NumberOfNodes);
                                rowsHolder.push(createData(
                                    change.doc.id,
                                    change.doc.data().NumberOfNodes ? change.doc.data().NumberOfNodes :  "tbd.",
                                    getFormattedDate(startDate),
                                    endDate,
                                    change.doc.data().ExecutionTime,
                                    change.doc.data().ClassName,
                                    change.doc.data().Status,
                                    getIconForRow(change.doc.data().Status, change.doc.data().ExceptionMessage ? change.doc.data().ExceptionMessage : null),
                                    change.doc.data().QueueNumber,
                                    change.doc.data().Label ? change.doc.data().Label : "No label provided",
                                ))
                            } else if (change.type === "removed") {
                                var index = rowsHolder.findIndex(item => item.callId === change.doc.id);
                                if (index !== -1) {
                                    rowsHolder.splice(index, 1);
                                }
                            } else if (change.type === "modified") {
                                var indexToModify = rowsHolder.findIndex(item => item.callId === change.doc.id);
                                let startDate = new Date(change.doc.data().TimestampStart.seconds * 1000)
                                let endDate = "tbd.";
                                if (change.doc.data().TimestampEnd !== null) {
                                    endDate = getFormattedDate(new Date(change.doc.data().TimestampEnd.seconds * 1000))
                                }
                                if (change.doc.data().Status === "Completed") {
                                    openJobDoneSnackbar()
                                }
                                if (change.doc.data().Status === "Failed") {
                                    openJobFailedSnackbar()
                                }
                                if (indexToModify !== -1) {
                                    let numberOfNodes;
                                    
                                    if(change.doc.data().NumberOfNodes === 0){
                                        numberOfNodes = 0;
                                    }else if (change.doc.data().NumberOfNodes > 0){
                                        numberOfNodes = change.doc.data().NumberOfNodes;
                                    }else{
                                        numberOfNodes = "tbd."
                                    }

                                    rowsHolder[indexToModify] = createData(
                                        change.doc.id,
                                        numberOfNodes,
                                        getFormattedDate(startDate),
                                        endDate,
                                        change.doc.data().ExecutionTime,
                                        change.doc.data().ClassName,
                                        change.doc.data().Status,
                                        getIconForRow(change.doc.data().Status, change.doc.data().ExceptionMessage ? change.doc.data().ExceptionMessage : null),
                                        change.doc.data().QueueNumber,
                                        change.doc.data().Label ? change.doc.data().Label : "No label provided",

                                    );
                                }
                            }
                        } else {
                            firebase.db.collection('APIkeys').doc(firebase.apiKey).collection("Calls").doc(change.doc.id).delete()
                        }
                    });
                    if (JSON.stringify(rows) !== JSON.stringify(rowsHolder)) {
                        setRows(rows.concat(rowsHolder));
                    }
                } else {
                    rowsHolder = [];
                    setRows([]);
                }
            });
        }
    };

    const getIconForRow = (status, exception) => {
        if (status === "Completed") {
            return <CheckCircleIcon className={classes.iconsComplete} />
        } else if (status === "Running") {
            return <FacebookProgress />;
        } else if (status === "Failed") {
            return <Tooltip classes={{ tooltip: classes.noMaxWidth }} title={exception} aria-label="add">
                <ErrorIcon className={classes.iconsError} />
            </Tooltip>
        } else if (status === "Queued") {
            return <AccessTimeIcon className={classes.iconsComplete} />
        }
    }
    useEffect(() => {
        setTimeout(() => {
            subscribeToApiCallsData();
        }, 1000)
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        }
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.callId);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setTimeout(() => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        })
    };

    const handleChangeDense = (event) => {

        setDense(event.target.checked);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const deleteRows = () => {
        var batch = firebase.db.batch();
        selected.forEach(doc => {
            batch.delete(firebase.callsDoc.doc(doc));
        })
        batch.commit();
        setSelected([])
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar selected={selected} rows={rows} numSelected={selected.length} deleteRows={(() => deleteRows())} />
                <TableContainer component={Paper} id="cont">
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.callId);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.callId)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.callId}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell align="left" padding="none" component="th" id={labelId} scope="row" >
                                                {row.label === "No label provided" ? <Chip disabled label={row.label} style={{height: "26px"}}></Chip> : <Chip style={{height: "26px"}} label={row.label}></Chip>}
                                            </TableCell>
                                            <TableCell align="right">{row.className}</TableCell>
                                            <TableCell align="right">{row.numberOfNodes}</TableCell>
                                            <TableCell align="right">{row.startTime}</TableCell>
                                            <TableCell align="right">{row.endTime}</TableCell>
                                            <TableCell align="right">{row.executionTime}</TableCell>
                                            <TableCell align="right" className={classes.completed}>{row.status} </TableCell>
                                            <TableCell align="center" className={classes.lastCells}> {row.queueNumber === 0 ? "" : row.queueNumber} </TableCell>
                                            <TableCell align="center" className={classes.lastCellsIcon}>{row.icon} </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={10} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <FormControlLabel
                        style={{ paddingLeft: "8px" }}
                        control={<Switch disabled checked={dense} onChange={handleChangeDense} />}
                        label="Dense padding"
                    /> */}
                    <div className={classes.innerDivTableBottom}>
                        <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
</div>
                    
                </div>
            </Paper>

        </div>
    );
}

const ApikeyData = (props) => {
    return (<EnhancedTable firebase={props.firebase} enqueueSnackbar={props.enqueueSnackbar}></EnhancedTable>);
}

export default withSnackbar(withFirebase(ApikeyData));
