// https://material-ui.com/
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import BruteForceNumber1 from "./BruteForceNumber1.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  Grid: {
    margin: "auto",
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <div style={{ paddingTop: "20px" }}>
      <div className={classes.root}>
        <Typography variant="subtitle1" gutterBottom>
          <b>Kubernetes cluster vs. single machine CPU</b>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            Execution times for brute forcing any 5 digits password containing
            ASCII values from 48 to 122 using the related SHA256 hash value. The
            hash value is passed to the algorithm. Each pod/task gets its own
            fixed start character and then calculates all hash
            values/combinations with that unique start charachter. When found
            the algorithm returns the 5 digits password in clear text.
            Iterations pr. pod/task 29.986.576, total iterations 2.219.006.624.
          </Grid>
          <Grid item xs={12} sm={8} style={{ marginTop: "-50px" }}>
            <BruteForceNumber1></BruteForceNumber1>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
