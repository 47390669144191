import * as React from "react";
// https://www.npmjs.com/package/react-google-charts
import { Chart } from "react-google-charts";

export default class TimelineChart extends React.Component {
  render() {
    return (
      <div>
         <Chart
          width={"100%"}
          height={"350px"}
          chartType="BarChart"
          loader={<div>Loading Chart</div>}
          data={[
            ["Platform", "1 Pod", "2 Pods", "4 Pods", "8 Pods", "16 Pods", "32 Pods", "64 Pods", "80 Pods"],
            ["Desktop", 143.58, 71.91, 37.43, 21.60, 13.55, 13.42, 12.93, 12.84],
            ["Laptop", 206.43, 123.73, 78.113, 49.99, 56.74, 54.21, 53.57, 53.47],
            ["Raspberry Pi Cluster", 1259.39, 683.13, 344.71, 177.74, 95.04, 54.14, 32.99, 29.84],
          ]}
          options={{
            colors: ['#5b9aa0','#d6d4e0', '#b8a9c9','#622569', '#588c7e' ],
            title: "8000 million iterations multi node single thread performance",
            chartArea: { width: "65%", height: "80%" },
            hAxis: {
              title: "Exectution time. Time in seconds",
              minValue: 0,
            },
            vAxis: {
              title: "Platform",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}
